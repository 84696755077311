import { Button, IconButton, SwitchInputText } from '~/components/UIElements';
import './InsightsSubheader.scss';
import { AlertTriangle, Download, Link } from 'react-feather';
import { useIntl } from 'react-intl';
import replaceLocationState from '~/utils/replaceLocationState';
import { InsightsResultsView } from '~/interfaces/contexts';

interface InsightsSubheaderProps {
    view: InsightsResultsView;
    previewMode: boolean;
    loading: boolean;
    showActions: boolean;
    handleView: () => void;
    onCopyLink?: () => void;
    onDownload?: (all?: boolean) => Promise<void>;
    handlePreviewMode: (resetTopicSelection: boolean, newMode: boolean) => void;
}

const mainClassName = 'insights-subheader';

const InsightsSubheader: React.FC<InsightsSubheaderProps> = ({
    view,
    previewMode,
    loading,
    showActions,
    onCopyLink,
    handleView,
    onDownload,
    handlePreviewMode,
}) => {
    const intl = useIntl();

    return (
        <div className={mainClassName}>
            <div className={mainClassName + '__actions'}>
                <SwitchInputText
                    leftValue={{
                        label: intl.formatMessage({
                            id: 'by_insight',
                        }),
                        value: InsightsResultsView.INSIGHTS,
                    }}
                    rightValue={{
                        label: intl.formatMessage({
                            id: 'by_document',
                        }),
                        value: InsightsResultsView.DOCUMENTS,
                    }}
                    handleToggle={() => {
                        handleView();
                        replaceLocationState();
                    }}
                    value={view}
                    disabled={loading}
                />

                {showActions && (
                    <div className={mainClassName + '__actions-btns'}>
                        <IconButton
                            variant="secondary"
                            onClick={() => onCopyLink?.()}
                            icon={Link}
                            title={intl.formatMessage({
                                id: 'copy_link',
                            })}
                            disabled={loading}
                        />

                        <Button
                            variant="secondary"
                            onClick={() => onDownload?.()}
                            iconBefore={Download}
                            disabled={loading}
                        >
                            {intl.formatMessage({ id: 'download' })} .xlsx
                        </Button>
                    </div>
                )}
            </div>
            <div className={mainClassName + '__preview'}>
                {previewMode && (
                    <>
                        <AlertTriangle />
                        <span>
                            {intl.formatMessage({
                                id: 'showing_subset_table',
                            })}
                        </span>
                        <span
                            onClick={handlePreviewMode}
                            className="f-underline clickeable"
                        >
                            {intl.formatMessage({ id: 'show_full_table' })}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default InsightsSubheader;
